@import "./mixins";
@import "./breakpoints";

p {
  color: var(--mainText);
  font-size: 13px;
  margin-bottom: 0!important;
  @include sm {
    font-size: 13px;
  }
  @include md {
    font-size: 14px;
  }
  // 992px window width and more
  @include lg {
    font-size: 15px;
  }
  // 1400px window width and more
  @include xl {
    font-size: 16px;
  }
}

a {
  font-size: 13px !important;
  @include sm {
    font-size: 13px !important;
  }
  @include md {
    font-size: 14px !important;
  }
  // 992px window width and more
  @include lg {
    font-size: 15px !important;
  }
  // 1400px window width and more
  @include xl {
    font-size: 16px !important;
  }
}

h1 {
  font-size: 18px !important;
  margin-bottom: 0!important;
  @include sm {
    font-size: 20px !important;
  }
  @include md {
    font-size: 30px !important;
  }
  // 992px window width and more
  @include lg {
    font-size: 34px !important;
  }
  // 1400px window width and more
  @include xl {
    font-size: 40px !important;
  }
}

h2 {
  font-size: 17px !important;
  margin-bottom: 0!important;
  @include sm {
    font-size: 22px !important;
  }
  @include md {
    font-size: 28px !important;
  }
  // 992px window width and more
  @include lg {
    font-size: 30px !important;
  }
  // 1400px window width and more
  @include xl {
    font-size: 32px !important;
  }
}

h3 {
  font-size: 16px !important;
  margin-bottom: 0!important;
  @include sm {
    font-size: 20px !important;
  }
  @include md {
    font-size: 24px !important;
  }
  // 992px window width and more
  @include lg {
    font-size: 26px !important;
  }
  // 1400px window width and more
  @include xl {
    font-size: 28px !important;
  }
}

h4 {
  font-size: 15px !important;
  margin-bottom: 0!important;
  @include sm {
    font-size: 17px !important;
  }
  @include md {
    font-size: 19px !important;
  }
  // 992px window width and more
  @include lg {
    font-size: 22px !important;
  }
  // 1400px window width and more
  @include xl {
    font-size: 24px !important;
  }
}

h5 {
  font-size: 14px !important;
  margin-bottom: 0!important;
  @include sm {
    font-size: 15px !important;
  }
  @include md {
    font-size: 16px !important;
  }
  // 992px window width and more
  @include lg {
    font-size: 17px !important;
  }
  // 1400px window width and more
  @include xl {
    font-size: 20px !important;
  }
}

h6 {
  font-size: 12px !important;
  margin-bottom: 0!important;
  @include sm {
    font-size: 13px !important;
  }
  @include md {
    font-size: 14px !important;
  }
  // 992px window width and more
  @include lg {
    font-size: 15px !important;
  }
  // 1400px window width and more
  @include xl {
    font-size: 16px !important;
  }
}

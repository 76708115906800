@import "./mixins";
@import "./breakpoints";


.App{
  min-height: 100vh!important;
  display: flex;
  flex-direction: column;
}

.div-filler{
 
  flex-grow: 1;
}
.noSpace{
  margin:0px;
  padding: 0px;
}

.bold{
  font-weight: 600!important;
}

.main-button{
  color:white!important;
  background-color: var(--mainTheme)!important;
  &:hover{
    background-color: var(--mainHover)!important;
  }  
  
}

.sedex-tooltip{
  color:white!important;
  background-color: var(--mainTheme)!important;
  &:hover{
    background-color: var(--mainHover)!important;
  } 
  margin-bottom: 20px!important;
  
}


.hero-button{
  color:white!important;
  background-color: var(--mainTheme)!important;
  &:hover{
    background-color: var(--mainHover)!important;
  }  
  font-size: 24px!important;
  
}

.main-svg-button{
  color:var(--mainText)!important; 
  fill:white!important;
  &:hover{
    color:white!important; 
    fill: var(--mainHover)!important;
  }
  
}

.cursor-pointer{
  cursor: pointer;
}

.section-title{
  text-align: center;
  color:var(--mainTheme);
  font-family: "Montserrat-Bold";
  max-width: 1200px;
}

.section-subtitle{
  text-align: center;
  font-family: "Montserrat-Bold";
  color:var(--secondaryText)
}


.player-wrapper{
  /*height: 400px;
  width: 100%;
  padding-top: 0px!important;*/
  //position: relative;
  //padding-top: 56.25%;
  height: 40vw!important;
  @include xl {
    height: 21vw!important;
  }
  //position: relative;
  //padding-top: 56.25%!important;
  
}

.react-player{
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1!important;
}

.fixed-bg{
  background-attachment: fixed!important;
  background-position: center!important;
  background-repeat: no-repeat!important;
  background-size: cover!important;
}

.Montserrat-bold{
  font-family: "Montserrat-Bold";
}

button:focus{
  box-shadow: none!important;
}

.theme-text{
  color: var(--mainTheme)!important;
  fill: var(--mainTheme)!important;
  stroke: var(--mainTheme)!important;
}
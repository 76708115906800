@import "./mixins";
@import "./breakpoints";
@import "~react-image-gallery/styles/css/image-gallery.css";
@import "~bootstrap/scss/bootstrap";
:root{
  --mainTheme:#085635;
  --mainHover: #009f48;
  --mainText:#5e5e60;
  --secondaryText:#60605f;
}

body {
  margin: 0;
  font-family: 'Montserrat'!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color:var(--mainText)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "BebasNeueBold";
  src: url("../Assets/Fonts/BebasNeueBold.eot");
  src: url("../Assets/Fonts/BebasNeueBold.eot?#iefix")
      format("embedded-opentype"),
    url("../Assets/Fonts/BebasNeueBold.woff2") format("woff2"),
    url("../Assets/Fonts/BebasNeueBold.woff") format("woff"),
    url("../Assets/Fonts/BebasNeueBold.ttf") format("truetype"),
    url("../Assets/Fonts/BebasNeueBold.svg#BebasNeueBold") format("svg");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}


@font-face {
  font-family: "Montserrat-Bold";
  src: url("../Assets/Fonts/Montserrat-Bold.eot");
  src: url("../Assets/Fonts/Montserrat-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("../Assets/Fonts/Montserrat-Bold.woff2") format("woff2"),
    url("../Assets/Fonts/Montserrat-Bold.woff") format("woff"),
    url("../Assets/Fonts/Montserrat-Bold.ttf") format("truetype"),
    url("../Assets/Fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../Assets/Fonts/Montserrat-Regular.eot");
  src: url("../Assets/Fonts/Montserrat-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("../Assets/Fonts/Montserrat-Regular.woff2") format("woff2"),
    url("../Assets/Fonts/Montserrat-Regular.woff") format("woff"),
    url("../Assets/Fonts/Montserrat-Regular.ttf") format("truetype"),
    url("../Assets/Fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
  font-display: swap;
  font-weight: 400;
  font-style: normal;
}

